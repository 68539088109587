<script>
import store from '@/infrastructure/store'
import NotificationsList from '@/features/notifications/components/NotificationsList'
import useRealtimeSubscriptions from '@/infrastructure/realtime/realtimeSubscriptions'

export default {
  components: {
    NotificationsList,
  },

  setup() {
    const { subscribeToRealtimeEvent } = useRealtimeSubscriptions()
    subscribeToRealtimeEvent('Notification:Created', () => store.dispatch('notifications/fetchUnreadNotifications'))
  },

  data() {
    return {
      isLoadingUnread: false,
      isMarkingAllRead: false,
    }
  },

  computed: {
    unreadNotificationCount: () => store.state.notifications.unreadNotificationCount,
    unreadNotifications: () => store.getters['notifications/unreadNotifications'],

    cardTitle() {
      return this.unreadNotificationCount === 1 ? `You have 1 unread notification` : `You have ${this.unreadNotificationCount} unread notifications`
    },
  },

  mounted() {
    this.loadUnreadNotification()
  },

  methods: {
    async loadUnreadNotification() {
      this.isLoadingUnread = true
      try {
        await Promise.all([
          //
          store.dispatch('notifications/fetchUnreadNotifications'),
          store.dispatch('notifications/fetchUnreadNotificationCount'),
        ])
      } finally {
        this.isLoadingUnread = false
      }
    },

    async markAllRead() {
      this.isMarkingAllRead = true
      try {
        await store.dispatch('notifications/markAllNotificationsRead')
      } finally {
        this.isMarkingAllRead = false
      }
    },
  },
}
</script>

<template>
  <div>
    <v-card :loading="isLoadingUnread">
      <v-card-title>
        <h4 class="mx-auto">{{ cardTitle }}</h4>
      </v-card-title>

      <v-card-text>
        <v-btn outlined block color="success" :disabled="isMarkingAllRead" :loading="isMarkingAllRead" @click="markAllRead">
          <v-icon>mdi-check</v-icon>
          Mark all read
        </v-btn>
      </v-card-text>
    </v-card>

    <NotificationsList :notifications="unreadNotifications" item-css-class="mt-3" />

    <div class="pt-3"></div>

    <v-card>
      <v-card-text>
        <v-btn block large text :to="{ name: 'read-notifications' }">Show read notifications</v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>
